import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { RichText, RichTextBlock } from 'prismic-reactjs';

import { graphql } from 'gatsby';

import LayoutFrame from '../components/layout/LayoutFrame';
import { Typography } from '@nelnet/unifi-components-react';
import CalloutGroup from '../components/layout/CalloutGroup';
import Breadcrumbs from '../components/layout/Breadcrumbs';
import DefinitionHeader from '../components/layout/DefinitionHeader';
import DetailsContent from '../components/layout/DetailsContent';

interface IGuidelinesDetailsProps {
  data: {
    prismic: {
      allGuideliness: {
        edges: [
          {
            node: {
              _meta: {
                uid: string;
              };
              title: RichTextBlock[];
              primary_page_color: string;
              page_font_color: string;
            };
          },
        ];
      };
      _allDocuments: {
        edges: [
          {
            node: {
              image: {
                url: string;
                alt: string;
              };
              title: RichTextBlock[];
              header_content: RichTextBlock[];
              body: {
                primary: {
                  additional_title: RichTextBlock[];
                  additional_description: RichTextBlock[];
                };
              };
              _meta: {
                uid: string;
                type: string;
                tags: string;
              };
            };
          },
        ];
      };
      allComponents: {
        edges: [
          {
            node: {
              _meta: {
                uid: string;
                tags: string;
              };
              title: string;
              description: string;
              main_content: string;
              body: {
                primary: {
                  example_title: string;
                  example_content: string;
                  example_graphic: {
                    url: string;
                    alt: string;
                  };
                  example_border: string;
                };
              };
              e_v_description: string;
              e_v_notification: {
                notification_content: string;
                notification_type: string;
              };
              primary_usage_list: {
                do_not_use_graphic: {
                  url: string;
                  alt: string;
                };
                do_not_use_text: string;
                use_graphic: {
                  url: string;
                  alt: string;
                };
                use_text: string;
              };
              usage_notification: {
                notification_content: string;
                notification_type: string;
              };
            };
          },
        ];
      };
      allFooters: {
        edges: [
          {
            node: {
              title: RichTextBlock[];
              subtitle: RichTextBlock[];
              button_text: RichTextBlock[];
              button_style: 'text' | 'outlined' | 'contained';
              button_link: {
                text: string;
              };
            };
          },
        ];
      };
    };
  };
}

const GuidelinesDetails: React.FunctionComponent<IGuidelinesDetailsProps> = ({ data }) => {
  // Required check for no data being returned
  const doc = data.prismic.allGuideliness.edges.slice(0, 1).pop();
  const detailsDoc = data.prismic._allDocuments.edges.slice(0, 1).pop();
  const footerDoc = data.prismic.allFooters.edges.slice(0, 1).pop();

  const uid = doc.node._meta.uid;
  const details_uid = detailsDoc.node._meta.uid;
  const page_title = detailsDoc.node.title;

  if (!doc) {
    return (
      <LayoutFrame currPath={`/${uid}/${details_uid}`}>
        <Helmet>
          <title>{RichText.asText(page_title)}</title>
        </Helmet>
        <Typography variant="h2" margin={{ bottom: 3 }}>
          {RichText.asText(page_title)}
        </Typography>
      </LayoutFrame>
    );
  }

  const page_font_color = doc.node.page_font_color.slice(-7);
  const page_color = doc.node.primary_page_color.slice(-7);

  const title = doc.node.title;

  const icon_url = detailsDoc.node.image.url;
  const icon_alt = detailsDoc.node.image.alt;
  const header_content = detailsDoc.node.header_content;

  const group = data.prismic._allDocuments.edges[0].node._meta.tags[0];
  const list_items = data.prismic.allComponents.edges;
  const additional_content = data.prismic._allDocuments.edges;

  const { button_text, button_style } = footerDoc.node;
  const footer_title = footerDoc.node.title;
  const footer_subtitle = footerDoc.node.subtitle;
  const button_link = footerDoc.node.button_link[0].text;

  return (
    <LayoutFrame currPath={`/${uid}/${details_uid}`} key={details_uid}>
      <Helmet>
        <title>{RichText.asText(page_title)}</title>
      </Helmet>
      <Breadcrumbs
        title={title}
        title_url={`/${uid}/`}
        subtitle={page_title}
        page_color={page_color}
        page_font_color={page_font_color}
      ></Breadcrumbs>
      <DefinitionHeader
        icon_url={icon_url}
        icon_alt={icon_alt}
        page_title={page_title}
        summary={header_content}
        title={title}
      ></DefinitionHeader>
      <DetailsContent
        group={group}
        page_color={page_color}
        page_font_color={page_font_color}
        list_items={list_items}
        additional_content={additional_content}
        page_title={page_title}
        parent_slug={uid}
      ></DetailsContent>
      <CalloutGroup
        title={footer_title}
        subtitle={footer_subtitle}
        button_text={button_text}
        button_style={button_style}
        button_url={button_link}
      ></CalloutGroup>
    </LayoutFrame>
  );
};

export default GuidelinesDetails;

export const query = graphql`
  query GuidelinesDetailsQuery($type: String, $id: String) {
    prismic {
      allGuideliness {
        edges {
          node {
            _meta {
              uid
            }
            title
            primary_page_color
            page_font_color
          }
        }
      }
      _allDocuments(type: $type, id: $id) {
        edges {
          node {
            ... on PRISMIC_Guideline_page {
              image
              title
              header_content
              body {
                ... on PRISMIC_Guideline_pageBodyAdditional_content {
                  primary {
                    additional_description
                    additional_title
                  }
                }
              }
            }
            _meta {
              uid
              type
              tags
            }
          }
        }
      }
      allComponents(sortBy: title_ASC, first: 50) {
        edges {
          node {
            _meta {
              uid
              tags
            }
            title
            description
            main_content
            body {
              ... on PRISMIC_ComponentBodyComponent_example {
                primary {
                  example_title
                  example_content
                  example_graphic
                  example_border
                }
              }
            }
            e_v_description
            e_v_notification {
              notification_content
              notification_type
            }
            primary_usage_list {
              do_not_use_graphic
              do_not_use_text
              use_graphic
              use_text
            }
            usage_notification {
              notification_content
              notification_type
            }
          }
        }
      }
      allFooters {
        edges {
          node {
            title
            subtitle
            button_text
            button_style
            button_link
          }
        }
      }
    }
  }
`;
